.nav-item {
    cursor: pointer;
}

nav .navbar-brand img {
    width: 45px
}

.navbar {
    padding-left: 10% !important;
    padding-right: 10% !important;
}

.nav-link {
    padding: 1rem 1rem !important;
    color: #929496;
}
