.Macro {
    text-align: left;
    font-family: 'Montserrat', sans-serif;
    background-color: #cfcfcf80;
}

.Macro .banner {
    background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("top.jpg");
    background-size: cover !important;
    background-position: 50% 40% !important;
    height: 40vh
}

.Macro footer {
    display: none !important;
}

.Macro .input-place p{
    font-weight: 300;
    font-size: 14px;
}
.Macro .input-place h4{
    font-weight: 600;
    font-size: 17px;
}
.Macro .section2 .col{
    height: 200px;
}

.Macro .banner .pl-5 {
    padding-left: 8rem !important;
}

.Macro .banner p {
    font-weight: 500;
}

.Macro .banner h2 {
    font-weight: bolder;
}

.Macro #information {
    padding-top: 40px;
}

.Macro #information h1 {
    font-weight: 500;
    font-size: 30px;
}

.Macro #information h2 {
    font-size: 17px;
    margin-top:25px ;
    margin-bottom:20px ;
    text-transform: uppercase;
    font-weight: 600;
}

.Macro #information h3 {
    font-size: 17px;
    font-weight: 600;
}



.Macro .input-form {
    padding: 0.6rem 1rem;
    border-radius: 5px;
    box-shadow: none;
    border: none;
    border: 1px solid #c1c5c8;
    width: 100%;
    margin-bottom: 17px;
    font-weight: 500;
    color: #545b62;
    padding-left: 45px;
}

.Macro .input-place {
    padding: 0.6rem 1rem;
    border-radius: 5px;
    box-shadow: none;
    border: none;
    border: 1px solid #c1c5c8;
    width: 100%;
    margin-bottom: 17px;
    font-weight: 500;
    color: #545b62;
    padding-left: 20px;
}

span.border {
    padding: 6px;
}

span.border1 {
    padding: 5px;
}

.Macro .input-borderless {
    width: 4rem;
    border: none;
}

.Macro .input-form.back-color {
    background-color: #cfcfcf80;
    border: none;
}



/*new css*/

#section1 {
    /* background: url('../img/top.jpg'); */
    background-image: linear-gradient(180deg, rgba(3, 3, 3, 0.28), rgba(3, 3, 3, 0.28)), url('./top.jpg');

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding-top: 4.5rem;
    padding-bottom: 4.5rem;
}

#section1 .heading {
    font-size: 2.5rem;
    color: white !important;
}

#section1 p {
    font-size: 1.4rem;
    color: white;

}

#section2 {
    padding-top: 5rem;
    padding-bottom: 5rem;
    background: #efefef
}

#section2 * {
    color: black;
    text-align: center;
}

#section2 .circle {
    border: 2px solid red;
    height: 170px;
    width: 170px;
    border-radius: 100%;
    padding-top: 2rem;
}

#section2 .circle h6 {
    font-size: 2rem;
    font-weight: 400;
}

#section2 .circle img {
    margin-bottom: 10px;
    margin-top: -10px;
}

#section2 .col-md-3 {
    justify-content: center;
    display: flex;
}

#section2 .card {
    border-radius: 20px;
    padding: 3rem 7rem;
}

#section2 h3 {
    text-align: left;
}

#section2 ul {
    display: flex;
    flex-direction: row-reverse;
}

#section2 .nav-link {
    background: white;
    border-radius: 0px;
    color: grey;
    width: 125px;
    font-size: 1rem;
    padding: .5rem 1rem !important;
    font-weight: normal
}

#section2 .nav-link.active {
    color: #fff;
    background-color: #007bff;
    background: #fff3e8 !important;
    border: 1px solid #ff855d;
    color: #ff855d !important;
    font-weight: 500 !important;
}


#section3 {
    background-image: linear-gradient(180deg, rgba(3, 3, 3, 0.28), rgba(3, 3, 3, 0.28)), url('./bottom.jpg');

    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
}

#section3 input::placeholder {
    font-weight: normal;
    color: rgba(3, 3, 3, 0.335)
}

#section3 input {
    padding: 0.47rem 0.8rem;
    border-radius: 3px;
    box-shadow: none;
    border: none;
    border: 1px solid #c1c5c8;
    width: 320px;
    margin-bottom: 17px;
    font-weight: 500;
    color: #c1c5c8;
    /* padding-left:45px; */
}

#section3 h3 {
    color: white
}

#section3 button {
    background: linear-gradient(90deg, #ff531e, #ff8f00);
    border: none;
    box-shadow: none;
    color: white;
    font-size: 1rem;
    font-weight: 600;
    border-radius: 3px;
    margin-right: 2%;
    padding: 0.5rem 3rem
}


.bold {
    font-weight: 700;
}
