.Forgot {
    text-align: left !important;
}

.Forgot .card-footer {
    background-color: white;
    border-top: none;
}

.Forgot .content .email::before {
    content: url(email-2.svg);
    width: 20px;
}


.Forgot .label {
    position: relative;
    width: 20px;
    top: -52px;
    left: 18px;
}

.Forgot .banner {
    background-size: cover !important;
    background-position: center !important;
    height: 100vh
}

.Forgot .container {
    width: 100vw;
    max-width: 100vw;
}


.Forgot .inputform input {
    padding: 0.6rem 1rem;
    border-radius: 5px;
    box-shadow: none;
    border: none;
    border: 1px solid #c1c5c8;
    width: 100%;
    margin-bottom: 17px;
    font-weight: 500;
    color: #545b62;
    padding-left: 45px;
}

.Forgot .inputform button {
    background: linear-gradient(90deg, #ff531e, #ff8f00);
    border: none;
    box-shadow: none;
    color: white;
    font-size: 1rem;
    font-weight: 600;
    border-radius: 3px;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    width: 49%;
    margin-right: 2%;
}

.Forgot .heading {
    font-size: 1.5rem;
    font-weight: bold;
}

.Forgot .content {
    width: 320px;
    margin: auto;
    flex-direction: column;
    position: relative;
    top: 47%;
    transform: translateY(-50%)
}

.Forgot .signbutton {
    background: none !important;
    border: 1px solid #ff8f00 !important;
    color: #ff8f00 !important
}


@media (max-width: 720px) {
    .Forgot .mobileform {
        padding-top: 120px;
        padding-left: 0px;
    }

    .Forgot .inputform button {
        width: 100%;
        margin-bottom: 17px;
    }

    .Forgot .buttongroup {
        display: block !important;
    }

    .Forgot .banner {
        height: 288px !important;
    }

    .Forgot .content {
        width: 88vw !important;
    }

    .Forgot .heading {
        margin-bottom: 35px
    }
}
