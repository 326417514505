footer {
    display: none;
    position: absolute;
    bottom: 0;
    width: 100%;
}

footer h3 {
    color: white;
    text-align: center;
    font-size: 1rem;
    padding-bottom: 12px;

}

footer .contentfooter {
    justify-content: space-around;
}

footer .contentfooter h3 {
    color: #c1c5c8;
}

@media (max-width: 720px) {
    footer {
        display: none;
    }
}
