.Profile {
    width: 80%;
    margin: 20px auto;
    text-align: center;
}

.modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto;
    position: absolute;
    right: 10px;
    top: 10px;
}

.Profile label {
    margin: 10px auto;
}

.Profile .card {
    box-shadow: 0 2px 3px #ccc;
    margin: auto;
}

.Profile .form-group {
    margin-bottom: 1rem;
    padding: 20px;
}


.Profile button {
    margin: 10px 0;
}

.ProfilePage {
    text-align: left;
}

.ProfilePage .heading2 {
    font-weight: 700;
    font-size: 2rem;
}

.ProfilePage .heading3 {
    font-size: 1.2rem;
    font-weight: 700
}

.ProfilePage .inputform input {
    margin-right: 17px;
    padding: 0.6rem 1rem;
    border-radius: 5px;
    box-shadow: none;
    border: none;
    border: 1px solid #c1c5c8;
    width: 260px;
}

.ProfilePage .inputform button {
    width: 260px;
    background: linear-gradient(90deg, #ff531e, #ff8f00);
    border: none;
    box-shadow: none;
    color: white;
    font-size: 1rem;
    font-weight: 600;
    border-radius: 4px;
}

.ProfilePage .heading4 {
    font-size: 1.1rem;
    font-weight: bold;
}

.ProfilePage .sub-back {
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position-y: -65px !important;
    border-radius: 5px !important;
    cursor: pointer;
}

.ProfilePage .heading5 {
    color: white;
    font-size: 1.7rem;
}

.ProfilePage .card-footer {
    background-color: white;
    border-top: none;
}

.ProfilePage .heading6 {
    color: white;
    font-size: 1rem;

}

@media (max-width: 720px) {
    .ProfilePage .container {
        padding: 1.3rem;
    }

    .ProfilePage .inputform input,
    .ProfilePage .inputform button {
        width: 100%;
        margin-bottom: 1rem;
        margin-right: 0px;
    }

    .ProfilePage .inputform button {
        height: 45px
    }

    .ProfilePage .md-margin {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem
    }

    .ProfilePage .navbar {
        padding-left: 5% !important;
    }

    .ProfilePage .sub-back {
        background-position-y: -25px;
    }
}
